.slider-magazine-logos{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 3rem;
    gap:80px;
    justify-content: center;
    align-items: center;
    
}

.logo-item{
    height: 50px;
    color: black;
}

.slider-magazine-logos a:first-child{
    margin-left: 3rem;
}