.container {
  display: flex;
  place-content: center;
  overflow: hidden;
  width: 100%;
  height: 100%;
  object-fit: cover;

}

.item {
  width: 100%;
  height: 100%;
  object-fit: cover;
  overflow: visible;
  stroke: rgb(0, 0, 0);
  stroke-width: 2;
  stroke-linejoin: round;
  stroke-linecap: round;
}
