footer{
    background-color: black;
    position: relative;
}


.nav-links-footer{
    color:white;
}

#najjat-logo-footer{
    margin: 0 1rem;
}

#najjat-logo-footer::after{
    content: ' ';
    position: absolute;
    width: 95%;
    height: 1px;
    left:50%;
    background-color: white;
    display: inline;
   transform: translateX(-50%);
}

.primary-footer-nav{
    margin: 2.5rem 1rem;
}